/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import '~bootstrap/scss/bootstrap.scss';
@import '~lightgallery/scss/lightgallery';

// Import toastr-bs4-alert
// @import '~ngx-toastr/toastr-bs4-alert';

// Import Fuse core library
// @import "@fuse/scss/core";

// Import app.theme.scss
// @import "app/app.theme";

// formio custom css
$darkNavy: #213140;
// $teal1: #66b3fb;
$teal1: #3c4252;
// $teal2: #4b9dea;
$teal2: #3c4252;
$charcoal: #555555;
$gold: #b6985a;

$activeShadow: 0 0 10px rgba($teal1, 0.5);

// *{
//   font-family: Muli, Helvetica Neue, Arial, sans-serif !important;
// }

body {
  // font-size: 1.4em;
  font-family:
    'Inter var',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
}

.formio,
.formio-form,
.formio-read-only {
  .fa:not(.material-icon) {
    font: normal normal normal 11px/2 FontAwesome !important;
  }
}

.formio-form {
  min-height: 0 !important;
}

.formio-read-only {
  .field-required::after {
    display: none;
  }

  .formio-component-textarea {
    .card-body {
      padding: 10px !important;
      min-height: 85px;
    }
  }

  .signature-pad-footer {
    display: none;
  }
}

.formio {
  input[type='text'] {
    font-size: 14px;
    padding: 17px 11px;
    border-radius: 4px;
  }

  input[type='text']:hover,
  textarea[type='text']:hover {
    box-shadow: 1px 1px 0 4px rgba($color: $teal1, $alpha: 0.1);
  }
}

.card-body {
  font-size: 14px;
  padding: 17px 24px;
  border-radius: 4px;
}

.form-control.input {
  border-radius: 4px 0 0 4px !important;
}

textarea[type='text'] {
  font-size: 14px;
  padding: 9px 11px;
  border-radius: 5px;
}

.col-form-label {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.6);
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.formio-component-panel {
  box-shadow: 1px 1px 10px -7px;
  animation: 0.15s ease-in 0s 1 slideInFromBottom;
  border-radius: 4px;
  margin-bottom: 15px;
}

.card-title {
  font-size: 20px;
  font-weight: 600;

  i {
    padding-left: 10px;
  }
}

.card-header {
  background-color: white !important;
  border: none !important;
  padding: 2.5rem 1.25rem 0.25rem 1.5rem !important;

  &.form-builder-panel {
    padding: 0.25rem !important;
  }
}

.card-body {
  .card-title {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .card-header {
    cursor: pointer;
    padding: 10px 0 5px 0 !important;
  }

  .card-header:hover {
    background: rgba(0, 0, 0, 0.04) !important;
  }
}

#alec-splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #002855;
  z-index: 99999;
  pointer-events: none;

  .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .logo {
    width: 128px;
    margin: 0 auto;

    img {
      filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
    }
  }

  .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;

    .spinner {
      position: absolute;
      overflow: hidden;
      left: 50%;
      margin-left: -50px;
      animation: outer-rotate 2.91667s linear infinite;

      .inner {
        width: 100px;
        height: 100px;
        position: relative;
        animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;

        .gap {
          position: absolute;
          left: 49px;
          right: 49px;
          top: 0;
          bottom: 0;
          border-top: 10px solid;
          box-sizing: border-box;
        }

        .left,
        .right {
          position: absolute;
          top: 0;
          height: 100px;
          width: 50px;
          overflow: hidden;

          .half-circle {
            position: absolute;
            top: 0;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border: 10px solid #4285f4;
            border-bottom-color: transparent;
            border-radius: 50%;
          }
        }

        .left {
          left: 0;

          .half-circle {
            left: 0;
            border-right-color: transparent;
            animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
          }
        }

        .right {
          right: 0;

          .half-circle {
            right: 0;
            border-left-color: transparent;
            animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
          }
        }
      }
    }
  }

  @keyframes outer-rotate {
    0% {
      transform: rotate(0deg) scale(0.5);
    }

    100% {
      transform: rotate(360deg) scale(0.5);
    }
  }

  @keyframes left-wobble {

    0%,
    100% {
      transform: rotate(130deg);
    }

    50% {
      transform: rotate(-5deg);
    }
  }

  @keyframes right-wobble {

    0%,
    100% {
      transform: rotate(-130deg);
    }

    50% {
      transform: rotate(5deg);
    }
  }

  @keyframes sporadic-rotate {
    12.5% {
      transform: rotate(135deg);
    }

    25% {
      transform: rotate(270deg);
    }

    37.5% {
      transform: rotate(405deg);
    }

    50% {
      transform: rotate(540deg);
    }

    62.5% {
      transform: rotate(675deg);
    }

    75% {
      transform: rotate(810deg);
    }

    87.5% {
      transform: rotate(945deg);
    }

    100% {
      transform: rotate(1080deg);
    }
  }
}

footer {
  padding: 0px 20px !important;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: #002855;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    top: $size * 0.25;
    font-size: 18px;
    line-height: $size * 0.5;
    color: #fff;
    font-weight: bold;
  }
}

.avatar-circle {
  @include avatar(40px);
}

.formio-form.formio-read-only {

  input[type='text']:hover,
  textarea[type='text']:hover {
    box-shadow: none !important;
  }

  .formio-component-radio.customCheck {
    .form-check-label {
      padding: 0.5rem 3rem;
      font-size: 14px;
      cursor: not-allowed !important;
    }

    .form-radio {
      input {
        display: none;
      }

      div.form-check-inline {
        margin: 0px;
        font-size: 1rem;
        line-height: 140%;
        font-weight: 600;
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        border: solid 1px #ddd;
        text-align: center;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0);
        transition:
          border-color 0.15s ease-out,
          color 0.25s ease-out,
          background-color 0.15s ease-out,
          box-shadow 0.15s ease-out;
        margin-right: -3px;

        &:first-of-type {
          border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
          border-radius: 0 4px 4px 0;
        }
      }

      .radio-selected {
        color: #fff;
        box-shadow: $activeShadow;
        border-color: $teal2;
        z-index: 1;
        background-color: rgba($color: $teal2, $alpha: 0.5);
      }

      div.form-check-inline:hover {
        box-shadow: none;
      }
    }
  }
}

.smallCheck {
  .formio-component-radio.customCheck {
    .form-check-label {
      cursor: pointer;
      padding: 0.38rem 1rem !important;
      font-size: 12px !important;
    }
  }
}

.formio-component-radio.customCheck {
  .form-check-label {
    padding: 0.5rem 3rem;
    cursor: pointer;
    font-size: 14px;
  }

  .form-radio {
    input {
      display: none;
    }

    div.form-check-inline {
      margin: 0px;
      font-size: 1rem;
      line-height: 140%;
      font-weight: 600;
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      border: solid 1px #ddd;
      text-align: center;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0);
      transition:
        border-color 0.15s ease-out,
        color 0.25s ease-out,
        background-color 0.15s ease-out,
        box-shadow 0.15s ease-out;
      margin-right: -3px;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }

    .radio-selected {
      color: #fff;
      box-shadow: $activeShadow;
      border-color: $teal2;
      z-index: 1;
      background-color: $teal2;
    }

    div.form-check-inline:hover {
      box-shadow: 1px 1px 0 8px rgba($color: $teal1, $alpha: 0.1);
    }
  }
}

.card-header {
  font-size: 1.2rem;
  font-weight: 500;
}

.form-control:disabled,
.form-control[readonly],
.form-control[disabled] {
  background-color: #fafafa !important;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
  cursor: not-allowed;
}

.form-control {
  border-radius: 5px;
}

//hide default formio loader

formio-loader {
  display: none !important;
}

.component-settings-button {
  width: 24px !important;
  height: 24px !important;

  i {
    position: relative;
    bottom: 5px;
    left: -4px;
  }
}

.right-aligned-header>.mat-content {
  justify-content: space-between !important;
}

.mat-content>mat-panel-title,
.mat-content>mat-panel-description {
  flex: 0 0 auto !important;
}

.mat-content {
  padding-top: 6px;
}

.formio {
  i {
    color: #fff !important;
  }

  .component-settings-button-move,
  .component-settings-button-edit-json,
  .component-settings-button-copy,
  .formio-button-add-row,
  .formio-button-remove-row,
  .component-settings-button-paste {
    i {
      color: #373a3c !important;
    }
  }
}

.compact-table tr {
  p {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.custom-label-margin {
  height: 100%;
  display: grid;
  align-content: center;
}

.selected-preset {
  padding: 10px 16px !important;
}

.k-grid-toolbar {
  display: flex !important;
  justify-content: flex-end !important;
}

.mat-step-icon-state-check,
.mat-step-icon-state-done {
  background-color: rgba($color: #00c851, $alpha: 1) !important;
}

.mat-step-icon-state-email {
  background-color: rgba($color: #037cd6, $alpha: 1) !important;
}

.mat-step-icon-state-clear {
  background-color: #f44336 !important;
}

.mat-step-icon-state-description {
  background-color: #ff8800 !important;
}

.mat-step-label {
  display: flex;
  flex-grow: 2;
}

.mat-step-text-label {
  display: flex;
  justify-content: space-between;
}

.mat-expansion-panel {
  animation: 0.15s ease-in 0s 1 slideInFromBottom;
}

.grid-header-dropdown {
  .k-input {
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
  }
}

.request-status {
  border: 1px solid;
  border-radius: 15px;
  // padding: 1px 20px;
  padding: 0.2rem 1rem;
  transition: background-color 0.1s ease-out 0s;
  color: #00c851;

  &.Completed {
    color: #00c851;
  }

  &.Active,
  &.Requested,
  &.InProgress {
    color: #ff8800;
  }

  &.Declined {
    color: #f44336 !important;
  }

  &.default-status-color {
    color: #ff8800 !important;
  }
}

.request-status-grid {
  color: #00c851;
  font-weight: 600;

  &.Completed {
    color: #00c851;
  }

  &.Active,
  &.Requested,
  &.InProgress {
    color: #ff8800;
  }

  &.Declined {
    color: #f44336 !important;
  }

  &.default-status-color {
    color: #ff8800 !important;
  }
}

.row-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button.btn-import-export {
    margin-left: 1px;
    border-radius: 0;
  }

  button.btn-import-export:first-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  button.btn-import-export:last-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0 10px 0 3px;
    min-width: fit-content;
  }
}

.search-wrapper {
  width: 100%;
  max-width: 480px;
  border-radius: 28px;
  overflow: hidden;
  // @include mat-elevation(1);

  // @include media-breakpoint('xs') {
  //   width: 100%;
  // }

  .search {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 18px;

    input {
      width: 100%;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      padding: 0 16px;
      border: none;
      outline: none;
    }
  }
}

.form-card {
  margin-bottom: 1.8rem;
  border-radius: 0px !important;
  // background: #fcfcfc !important;
  box-shadow: none !important;

  .form-heading {
    font-size: 13px;
    word-spacing: 1px;
    color: #272525eb;
  }

  .circle-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 2em !important;
    text-align: center;
    vertical-align: middle;
  }
}

// .form-card:hover{
//   box-shadow: 1px 5px 11px -3px rgba(13,13,13,0.75) !important;
//   transition: box-shadow 0.25s ease-out;
// }

.k-button {
  font-family:
    'Inter var',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

//material styling for kendo combobox and multiselect

.k-multiselect {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-family:
    Muli,
    Helvetica Neue,
    Arial,
    sans-serif !important;

  .k-button {
    font-size: 14px;
    font-family:
      Muli,
      Helvetica Neue,
      Arial,
      sans-serif !important;
  }
}

.k-combobox ::selection {
  background-color: #3f51b5;
  color: white;
}

.k-form .k-combobox {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.k-combobox {
  width: 200px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family:
    Muli,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.125;
  text-align: left;
  white-space: nowrap;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-combobox .k-dropdown-wrap {
  padding: 0.6em 0.75em 0.35em 0.75em !important;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  transition: all 0.1s ease;
  outline: 0;
  cursor: initial;

  .k-input {
    padding: 0 !important;
  }
}

.k-combobox .k-clear-value {
  visibility: hidden;
}

.k-combobox:hover .k-clear-value,
.k-combobox.k-state-hover .k-clear-value,
.k-combobox.k-state-focused .k-clear-value,
.k-combobox.k-state-focus .k-clear-value,
.k-combobox .k-dropdown-wrap.k-state-hover .k-clear-value,
.k-combobox .k-dropdown-wrap.k-state-focused .k-clear-value {
  visibility: visible;
}

.k-combobox:focus-within .k-clear-value {
  visibility: visible;
}

.k-combobox .k-select {
  border-radius: 0 0;
  padding: 8px 4px;
  border-width: 0 0 0 0px;
  box-sizing: border-box;
  border-style: solid;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: center;
  cursor: pointer;
}

.k-combobox[dir='rtl'],
.k-rtl .k-combobox {
  text-align: right;
}

.k-combobox[dir='rtl'] .k-select,
.k-rtl .k-combobox .k-select {
  border-radius: 0 0;
  border-width: 0 0px 0 0;
}

.k-combobox .k-dropdown-wrap {
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.k-combobox .k-dropdown-wrap.k-state-disabled {
  border-bottom-style: dashed;
}

.k-combobox .k-dropdown-wrap {
  border-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}

.k-combobox .k-dropdown-wrap.k-invalid,
.k-combobox .k-dropdown-wrap.k-invalid:hover,
.k-combobox .k-dropdown-wrap.k-state-invalid {
  border-color: #f44336;
}

.k-combobox .k-select {
  color: rgba(0, 0, 0, 0.54);
}

.k-combobox> :hover,
.k-combobox .k-state-hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.k-combobox> :hover .k-select,
.k-combobox .k-state-hover .k-select {
  color: rgba(0, 0, 0, 0.87);
}

.k-combobox>.k-state-focused {
  border-color: #3f51b5;
}

.k-combobox.k-state-invalid .k-dropdown-wrap,
.k-combobox.ng-invalid.ng-touched .k-dropdown-wrap,
.k-combobox.ng-invalid.ng-dirty .k-dropdown-wrap {
  border-color: #f44336;
}

.k-combobox.k-state-hover .k-dropdown-wrap {
  border-color: rgba(0, 0, 0, 0.87);
}

.k-combobox.k-state-focus .k-dropdown-wrap {
  border-color: #3f51b5;
}

.k-combobox.k-invalid .k-dropdown-wrap {
  border-color: #f44336;
}

.input-group .k-dropdown-wrap .k-select,
.input-group .k-multiselect-wrap .k-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-widget.form-control-sm .k-dropdown-wrap .k-select {
  padding: 0.25rem;
}

.k-widget.form-control-sm .k-select {
  width: 1.8125rem;
}

.k-widget.form-control-lg .k-dropdown-wrap .k-select {
  padding: 0.5rem;
}

.k-widget.form-control-lg .k-select {
  width: 2.875rem;
}

.k-combobox .k-select {
  border-radius: 0 0;
  padding: 8px 4px;
  border-width: 0 0 0 0px;
  box-sizing: border-box;
  border-style: solid;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: center;
  cursor: pointer;
}

.k-combobox .k-select {
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0);
}

.k-combobox> :hover .k-select,
.k-combobox .k-state-hover .k-select {
  color: rgba(0, 0, 0, 0.87);
}

.k-popup {
  font-size: 14px !important;
  font-family:
    Muli,
    Helvetica Neue,
    Arial,
    sans-serif !important;
}

//multiselect

.k-multiselect ::selection {
  background-color: #3f51b5;
  color: white;
}

.k-form .k-multiselect {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.k-multiselect.form-control {
  height: auto;
}

.input-group .k-multiselect-wrap {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .k-multiselect-wrap .k-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .k-widget.form-control:not(:first-child):not(:last-child) .k-multiselect-wrap {
  border-radius: 0;
}

.input-group-prepend+.k-widget .k-multiselect-wrap {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.k-multiselect {
  width: 100%;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.125;
  text-align: left;
  white-space: nowrap;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-multiselect .k-loading-hidden {
  visibility: hidden;
}

.k-multiselect .k-multiselect-wrap {
  padding: 0 32px 0 0;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  transition: all 0.1s ease;
  cursor: text;
  outline: 0;
}

.k-multiselect .k-multiselect-wrap .k-input,
.k-dropdowntree .k-multiselect-wrap .k-input {
  float: left;
}

.k-multiselect .k-multiselect-wrap::before,
.k-dropdowntree .k-multiselect-wrap::before {
  content: '';
  width: 0;
  height: calc(1.125em + 16px);
  float: left;
}

.k-multiselect .k-multiselect-wrap ul,
.k-dropdowntree .k-multiselect-wrap ul {
  vertical-align: top;
}

.k-multiselect .k-multiselect-wrap li.k-button,
.k-dropdowntree .k-multiselect-wrap li.k-button {
  float: left;
}

.k-multiselect .k-multiselect-wrap li .k-icon,
.k-dropdowntree .k-multiselect-wrap li .k-icon {
  cursor: pointer;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  min-height: calc(2em + 6px);
  padding: 2px 8px;
  margin: 2px 0 0 2px;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  line-height: inherit;
  white-space: normal;
}

.k-multiselect .k-multiselect-wrap .k-button .k-select,
.k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.k-multiselect .k-multiselect-wrap .k-button:last-child,
.k-dropdowntree .k-multiselect-wrap .k-button:last-child {
  margin-bottom: 2px;
}

.k-multiselect .k-multiselect-wrap .k-searchbar,
.k-dropdowntree .k-multiselect-wrap .k-searchbar {
  float: left;
  width: auto;
}

.k-multiselect .k-multiselect-wrap .k-searchbar .k-input,
.k-dropdowntree .k-multiselect-wrap .k-searchbar .k-input {
  padding-right: 0;
}

.k-multiselect .k-multiselect-wrap .k-i-loading,
.k-dropdowntree .k-multiselect-wrap .k-i-loading {
  width: 32px;
  height: 34px;
  position: absolute;
  top: 0;
  right: 0;
}

.k-multiselect .k-multiselect-wrap .k-clear-value,
.k-dropdowntree .k-multiselect-wrap .k-clear-value {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.k-multiselect .k-multiselect-wrap:hover .k-clear-value,
.k-dropdowntree .k-multiselect-wrap:hover .k-clear-value,
.k-multiselect .k-multiselect-wrap.k-state-hover .k-clear-value,
.k-dropdowntree .k-multiselect-wrap.k-state-hover .k-clear-value,
.k-multiselect .k-multiselect-wrap.k-state-focused .k-clear-value,
.k-dropdowntree .k-multiselect-wrap.k-state-focused .k-clear-value {
  visibility: visible;
}

.k-multiselect.k-state-hover>.k-multiselect-wrap .k-clear-value,
.k-state-hover.k-dropdowntree>.k-multiselect-wrap .k-clear-value,
.k-multiselect.k-state-focused>.k-multiselect-wrap .k-clear-value,
.k-state-focused.k-dropdowntree>.k-multiselect-wrap .k-clear-value,
.k-multiselect.k-state-focus>.k-multiselect-wrap .k-clear-value,
.k-state-focus.k-dropdowntree>.k-multiselect-wrap .k-clear-value {
  visibility: visible;
}

.k-multiselect[dir='rtl'],
.k-dropdowntree[dir='rtl'],
.k-rtl .k-multiselect,
.k-rtl .k-dropdowntree {
  text-align: right;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap,
.k-rtl .k-multiselect .k-multiselect-wrap,
.k-rtl .k-dropdowntree .k-multiselect-wrap {
  padding-left: 32px;
  padding-right: 0;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap::before,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap::before,
.k-multiselect[dir='rtl'] .k-multiselect-wrap li.k-button,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap li.k-button,
.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-input,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-input,
.k-rtl .k-multiselect .k-multiselect-wrap::before,
.k-rtl .k-dropdowntree .k-multiselect-wrap::before,
.k-rtl .k-multiselect .k-multiselect-wrap li.k-button,
.k-rtl .k-dropdowntree .k-multiselect-wrap li.k-button,
.k-rtl .k-multiselect .k-multiselect-wrap .k-input,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-input {
  float: right;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-searchbar,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-searchbar,
.k-rtl .k-multiselect .k-multiselect-wrap .k-searchbar,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-searchbar {
  float: right;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-searchbar .k-input,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-searchbar .k-input,
.k-rtl .k-multiselect .k-multiselect-wrap .k-searchbar .k-input,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-searchbar .k-input {
  padding-left: 0;
  padding-right: 0px;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-button,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-button,
.k-rtl .k-multiselect .k-multiselect-wrap .k-button,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-button {
  width: auto;
  margin-right: 2px;
  margin-left: 0;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-button .k-select,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-button .k-select,
.k-rtl .k-multiselect .k-multiselect-wrap .k-button .k-select,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  margin-left: 0;
  margin-right: 16px;
}

.k-multiselect[dir='rtl'] .k-clear-value,
.k-dropdowntree[dir='rtl'] .k-clear-value,
.k-multiselect[dir='rtl'] .k-i-loading,
.k-dropdowntree[dir='rtl'] .k-i-loading,
.k-rtl .k-multiselect .k-clear-value,
.k-rtl .k-dropdowntree .k-clear-value,
.k-rtl .k-multiselect .k-i-loading,
.k-rtl .k-dropdowntree .k-i-loading {
  right: auto;
  left: 0;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  min-height: 0;
  border-width: 1px;
  padding: 1em 0.75em !important;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.12) !important;
  // border-top-color: transparent !important;
}

.k-multiselect .k-multiselect-wrap .k-select,
.k-dropdowntree .k-multiselect-wrap .k-select {
  border-width: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  margin: 4px 4px 0 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 2em;
  border-radius: 5em;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 2;
  text-transform: none;
}

.k-multiselect .k-multiselect-wrap .k-button::before,
.k-dropdowntree .k-multiselect-wrap .k-button::before {
  display: none;
}

.k-multiselect .k-multiselect-wrap .k-button .k-select,
.k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  margin-left: 0.5em;
  margin-right: -0.2em;
  border-radius: 50%;
}

.k-multiselect .k-multiselect-wrap .k-button .k-select .k-icon,
.k-dropdowntree .k-multiselect-wrap .k-button .k-select .k-icon {
  opacity: 1;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
}

.k-multiselect .k-multiselect-wrap .k-button:last-child,
.k-dropdowntree .k-multiselect-wrap .k-button:last-child {
  margin-bottom: 4px;
}

.k-multiselect.k-state-disabled>.k-multiselect-wrap,
.k-state-disabled.k-dropdowntree>.k-multiselect-wrap,
.k-multiselect .k-multiselect-wrap.k-state-disabled,
.k-dropdowntree .k-multiselect-wrap.k-state-disabled {
  border-bottom-style: dashed;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-button,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-button,
.k-rtl .k-multiselect .k-multiselect-wrap .k-button,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-button {
  margin-left: 4px;
  margin-right: 0;
}

.k-multiselect[dir='rtl'] .k-multiselect-wrap .k-button .k-select,
.k-dropdowntree[dir='rtl'] .k-multiselect-wrap .k-button .k-select,
.k-rtl .k-multiselect .k-multiselect-wrap .k-button .k-select,
.k-rtl .k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  margin-left: -0.2em;
  margin-right: 0.5em;
}

.k-multiselect.k-state-focused>.k-multiselect-wrap,
.k-state-focused.k-dropdowntree>.k-multiselect-wrap {
  border-color: #3f51b5;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  border-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}

.k-multiselect .k-multiselect-wrap>.k-readonly,
.k-dropdowntree .k-multiselect-wrap>.k-readonly {
  opacity: 0.6;
}

.k-multiselect .k-multiselect-wrap .k-button .k-icon,
.k-dropdowntree .k-multiselect-wrap .k-button .k-icon {
  opacity: 0.6;
}

.k-multiselect .k-multiselect-wrap .k-button .k-icon:hover,
.k-dropdowntree .k-multiselect-wrap .k-button .k-icon:hover,
.k-multiselect .k-multiselect-wrap .k-button .k-icon.k-state-hover,
.k-dropdowntree .k-multiselect-wrap .k-button .k-icon.k-state-hover {
  opacity: 1;
}

.k-multiselect .k-multiselect-wrap .k-button:active,
.k-dropdowntree .k-multiselect-wrap .k-button:active,
.k-multiselect .k-multiselect-wrap .k-button.k-state-active,
.k-dropdowntree .k-multiselect-wrap .k-button.k-state-active {
  border-color: #e0e0e0;
  background-color: #e0e0e0;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.k-multiselect .k-multiselect-wrap .k-button:focus .k-icon,
.k-dropdowntree .k-multiselect-wrap .k-button:focus .k-icon,
.k-multiselect .k-multiselect-wrap .k-button.k-state-focused .k-icon,
.k-dropdowntree .k-multiselect-wrap .k-button.k-state-focused .k-icon {
  opacity: 1;
}

.k-multiselect .k-multiselect-wrap.k-invalid,
.k-dropdowntree .k-multiselect-wrap.k-invalid,
.k-multiselect .k-multiselect-wrap.k-invalid:hover,
.k-dropdowntree .k-multiselect-wrap.k-invalid:hover,
.k-multiselect .k-multiselect-wrap.k-state-invalid,
.k-dropdowntree .k-multiselect-wrap.k-state-invalid {
  border-color: #f44336;
}

.k-multiselect.k-state-invalid .k-multiselect-wrap,
.k-state-invalid.k-dropdowntree .k-multiselect-wrap,
.k-multiselect.ng-invalid.ng-touched .k-multiselect-wrap,
.ng-invalid.ng-touched.k-dropdowntree .k-multiselect-wrap,
.k-multiselect.ng-invalid.ng-dirty .k-multiselect-wrap,
.ng-invalid.ng-dirty.k-dropdowntree .k-multiselect-wrap {
  border-color: #f44336;
}

.k-multiselect.k-state-focus .k-multiselect-wrap,
.k-state-focus.k-dropdowntree .k-multiselect-wrap {
  border-color: #3f51b5;
}

.k-multiselect.k-invalid .k-multiselect-wrap,
.k-invalid.k-dropdowntree .k-multiselect-wrap {
  border-color: #f44336;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  box-shadow: none;
}

.k-multiselect .k-multiselect-wrap .k-button:hover,
.k-dropdowntree .k-multiselect-wrap .k-button:hover,
.k-multiselect .k-multiselect-wrap .k-button.k-state-hover,
.k-dropdowntree .k-multiselect-wrap .k-button.k-state-hover {
  background-color: #d8d8d8;
}

.k-multiselect .k-multiselect-wrap .k-button:focus,
.k-dropdowntree .k-multiselect-wrap .k-button:focus,
.k-multiselect .k-multiselect-wrap .k-button.k-state-focused,
.k-dropdowntree .k-multiselect-wrap .k-button.k-state-focused {
  background-color: #c2c2c2;
}

.k-multiselect .k-multiselect-wrap .k-button .k-select,
.k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  color: rgba(0, 0, 0, 0.54);
}

.k-multiselect .k-multiselect-wrap .k-button .k-select:hover,
.k-dropdowntree .k-multiselect-wrap .k-button .k-select:hover {
  color: rgba(0, 0, 0, 0.87);
}

.k-clear-value {
  width: 32px;
  height: 34px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  cursor: pointer;
  opacity: 0.5;
}

// modern-layout {
//   .mat-slide-toggle-content {
//     padding-top: 0.45rem !important;
//   }
// }

fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-active .fuse-horizontal-navigation-item-title,
fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-active-forced .fuse-horizontal-navigation-item-title {
  font-weight: 800 !important;
}

.k-dropdown .k-dropdown-wrap {
  background-color: white;
  padding: 8px 0;
  border-radius: 6px;
}

.k-grid {
  font-size: 0.8em !important;
  font-family: inherit;
}

div.choices__list.choices__list--dropdown {
  min-width: fit-content;
}

div.choices__item {
  span {
    color: #495057 !important;
  }
}

.mat-button-wrapper {
  justify-content: space-between;
  gap: 5px;
}

.message-box {
  padding: 16px;
  background: #607d8b;
  border-left: 6px solid #37474f;
  color: rgba(255, 255, 255, 1);

  &.error {
    background: #ef5350;
    border-left-color: #b71c1c;
  }

  &.warning {
    background: #ffecb3;
    border-left-color: #ffc107;
    color: rgba(0, 0, 0, 0.87);
  }

  &.success {
    background: #4caf50;
    border-left-color: #2e7d32;
  }

  &.info {
    background: #b3e5fc;
    border-left-color: #03a9f4;
    color: rgba(0, 0, 0, 0.87);
  }
}

.message-box.custom-message {
  background: #1565c038 !important;
  border-left-color: #1565c0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: white !important;
}

.customList ol,
.customList ul {
  list-style: auto !important;
  padding: 0 0 0 40px !important;
}

.mat-raised-button.mat-accent {
  color: white !important;
}

.form-control[disabled] {
  input:disabled {
    display: none;
  }
}

.actions {
  .mat-form-field-infix {
    border-top: 0.6em solid transparent;
  }
}

.mat-menu-panel {
  max-width: 302px !important;
}

.upload-button .mat-button-wrapper {
  position: initial !important;
}

.form-builder-panel .form-builder-group-header {
  padding: 0rem !important;
}

.input-nowrap .input-group {
  flex-wrap: nowrap !important;
  align-items: flex-start !important;
}

#cmbProject {
  width: 500px !important;
}

.k-popup {
  min-width: 150px !important;
}

.k-widget.k-grid {
  border-radius: 1rem;

  .k-grid-toolbar.k-toolbar {
    border-radius: 1rem 1rem 0 0;
  }

  .k-grid-container,
  .k-widget.k-pager-wrap.k-grid-pager {
    border-radius: 0 0 1rem 1rem;
  }

  &.has-pager .k-grid-container {
    border-radius: 0;
  }

  .k-column-title,
  .k-header,
  th,
  td {
    overflow: visible;
    white-space: normal;
  }
}

.k-dropdown .k-dropdown-wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.white-image {
  filter: brightness(0) invert(1);
}

.footer-text {
  font-size: 0.7rem;
}

.mat-dialog-container {
  padding: 0 !important;
}

.with-text {
  vertical-align: text-bottom;
}

.with-title {
  vertical-align: middle;
}

.qrcode canvas {
  margin: 0 auto;
}

.k-table-md {
  font-size: 1em !important;
}

.k-button-solid-primary {
  background: var(--fuse-primary) !important;
  background-color: var(--fuse-primary) !important;
}

.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;

  img {
    object-fit: contain;
  }
}

.gallery-wrapper .ngx-gallery-preview-icons .ngx-gallery-icon i.fa-arrow-circle-down::before {
  content: url('https://api.iconify.design/octicon/download-16.svg?color=white&width=32');
  // font-size: 50px !important;
}


.gallery-wrapper .ngx-gallery-preview-icons .ngx-gallery-icon i.fa-times-circle::before {
  content: url('https://api.iconify.design/mingcute/close-line.svg?color=white&width=32');
}

.gallery-wrapper .ngx-gallery-icon .ngx-gallery-icon-content .fa-chevron-right::before {}

.ngx-gallery-thumbnails .ngx-gallery-thumbnail,
.ngx-gallery-thumbnails .ngx-gallery-thumbnail.ngx-gallery-active {
  border: none !important;
}

.spinner_overlay,
.z-index {
  z-index: 99999 !important;
}